// extracted by mini-css-extract-plugin
export var CardWrapper = "Tkid-module--CardWrapper--6fpVO";
export var CarrouselWrapper = "Tkid-module--CarrouselWrapper--g5Sy9";
export var DescriptionWrapper = "Tkid-module--DescriptionWrapper--JBW6n";
export var ExpoWrapper = "Tkid-module--ExpoWrapper--Rnlkg";
export var NameWrapper = "Tkid-module--NameWrapper--kqCfi";
export var PdpWrapper = "Tkid-module--PdpWrapper--rTMr5";
export var PhotosWrapper = "Tkid-module--PhotosWrapper--sCXLj";
export var ProfilWrapper = "Tkid-module--ProfilWrapper--7JuSR";
export var VideoWrapper = "Tkid-module--VideoWrapper--l+jJH";
export var VideosWrapper = "Tkid-module--VideosWrapper--84rXM";
export var WorkWrapper = "Tkid-module--WorkWrapper--eReUl";
export var WorksWrapper = "Tkid-module--WorksWrapper--UDtrX";
export var Wrapper = "Tkid-module--Wrapper--VN4yC";